import React from "react";
import Logo from "../assets/logo.svg";
import Navbar from "react-bootstrap/Navbar";
import Card from "react-bootstrap/Card";
import Icon from "@mdi/react";
import { mdiPhoneClassic } from "@mdi/js";
const Header = () => {
  const APP_NAME = process.env.REACT_APP_NAME;
  return (
    <header>
      <Navbar className="justify-content-between" bg="white" expand="lg">
        <Navbar.Brand href="/">
          <img
            alt=""
            src={Logo}
            width="100"
            height="35"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Icon
              path={mdiPhoneClassic}
              title="Contato SagaTech"
              size={1.0}
              //horizontal
              //vertical
              //rotate={90}
              color="#005162"
              //spin
            />
            <strong> +55 38 3251-2204</strong>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
      <section
        className="text-center"
        data-testid="section-welcome"
        id="section-welcome"
      >
        <Card>
          <Card.Body>
            <p>
              Telemetria <strong>SATS</strong>!
            </p>
            <p>
              Este serviço permite o monitoramento dos <strong>pontos</strong> com
              telemetria na cidade de <strong>{APP_NAME}</strong>
              .
            </p>
          </Card.Body>
        </Card>
        <br />
      </section>
    </header>
  );
};
export default Header;
